<template>
  <v-card
    min-height="100vh"
    flat
    color="transparent"
    class="xw-full"
    :class="[tabletDown ? '' : 'xp-[1em]']"
  >
    <v-btn
      text
      depressed
      class="text-none xtext-[#9A9A9A] xtext-[14px] xleading-[17px]"
      @click.stop="goBack"
    >
      <v-icon left>mdi-chevron-left</v-icon> Go back
    </v-btn>
    <div
      :class="[mdUp ? 'xh-[100px]' : '']"
      class="xgap-1 xmt-[2px] md:xmb-[1em] xgrid sm:xgrid-cols-3 xgrid-cols-1 xp-[1em] xjustify-center xitems-center xbg-[#fff] xrounded-[10px] xgap-y-4 md:x-gap-y-0"
      v-if="project && !fetching"
    >
      <div
        class="xcol-span-1 xflex xflex-col md:xpr-[2em] xjustify-start xitems-stretch"
      >
        <h3
          class="xmb-1 xfont-[400] xtext-[16px] xleading-[20px] xtext-[#898989]"
        >
          Project Name
        </h3>
        <h1 class="xtext-[30px] xleading-[36.5px] xfont-[700]">
          {{ project.name | ucwords }}
        </h1>
      </div>
      <div class="xcol-span-1 xflex xflex-col xjustify-start xitems-stretch">
        <h3
          class="xmb-1 xfont-[400] xtext-[16px] xleading-[20px] xtext-[#898989]"
        >
          Admin
        </h3>
        <avatar
          v-if="project && project.admin"
          class="xfont-[600] xleading-[20px]"
          :user="project.admin"
          with-name
        ></avatar>
        <h1 class="xtext-[18px] xleading-[36.5px]" v-else>No admin assigned</h1>
      </div>
      <div class="xcol-span-1 xflex xflex-col xjustify-start xitems-stretch">
        <h3
          class="xmb-1 xfont-[400] xtext-[16px] xleading-[20px] xtext-[#898989]"
        >
          Data Center
        </h3>
        <h1
          class="xtext-[18px] xleading-[36.5px]"
          v-if="project && project.data_center"
        >
          {{ project.data_center.name }}
        </h1>
        <h1 class="xtext-[18px] xleading-[36.5px]" v-else>
          No data center assigned
        </h1>
      </div>
    </div>
    <!-- <pre>{{ project }}</pre> -->
    <router-view :key="$route.fullPath"></router-view>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: "projects",
      loading: false,
      counts: 0,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("projects", ["fetching", "project"]),
    admin() {
      if (!this.project) return null;
      return this.project.admin || this.project.members[0] || null;
    },
    tabs() {
      return [
        {
          name: "Users",
          to: {
            name: "app-project-monthly",
            params: { year_month: moment().format("YYYY-MM") },
          },
          alias: [
            "app-project-daily",
            "app-project-monthly",
            "app-project-weekly",
          ],
          counts: this.counts,
        },
      ];
    },
  },
  created() {
    this.$event.$on("project-count", (counts) => {
      this.counts = counts;
    });
  },
  watch: {
    "$route.params.project_id": {
      handler: function (val) {
        val && this.fetchProject(val);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("projects", ["fetchProject"]),
    goBack() {
      this.$router.push({
        name: "app-project-report-monthly",
        params: { year_month: moment().format("YYYY-MM") },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
